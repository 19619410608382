

















import { Component, Vue } from 'vue-property-decorator'
import { isIOS } from '@/common/utils'
@Component
export default class extends Vue {
  handleJump() {
    if (isIOS) {
      if (location.href.includes('test')) {
        window.location.href =
          'https://qiniu.kigo.top/iOS-web-test/videoGame.html#/taskcenter'
      } else {
        window.location.href =
          'https://qiniu.kigo.top/iOS-web/videoGame.html#/taskcenter'
      }
    } else {
      if (location.href.includes('test')) {
        window.location.href =
          'https://webtest.kigo.top/h5/main/videoGame.html#/taskcenter'
      } else {
        window.location.href =
          'https://web.kigo.top/h5/main/videoGame.html#/taskcenter'
      }
    }
  }
}
